import {VariantEnum} from "../../../backend/functions/tile/tile";
import {
    isValidEmail,
    isValidField,
    isValidFile,
    isValidName,
    isValidUrl
} from "../../../helpers/utils";
import {ICreationForm} from "../../../models/ICreationForm";

type ValidationRules = {
    [K in keyof ICreationForm | 'file']: { rule: (value: string) => boolean; message: string }[]
}

export const validationRules: ValidationRules = {
    link: [
        { rule: isValidField, message: "required" },
        { rule: isValidUrl, message: "Enter your website link in the format: www.yourwebsite.com" }
    ],
    text: [
        { rule: isValidField, message: "required" }
    ],
    file: [
        { rule: isValidFile, message: "required" }
    ],
    email: [
        {  rule: isValidEmail, message: "Enter your email address in the format: yourname@example.com" },
        {  rule: isValidField, message: "required" }
    ],
    name: [
        { rule: isValidName, message: "Must contain at least 3 characters" },
        { rule: isValidField, message: "required" }
    ]
};
