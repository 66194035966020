import { QueryDocumentSnapshot } from 'firebase/firestore';
import {PaymentInfoResponse} from "./payment";

export const createPaymentFromDoc = (doc: QueryDocumentSnapshot): PaymentInfoResponse => {
    const data = doc.data();

    return {
        id: doc.id,
        userId: data.userId,
        amount: data.amount,
        cellId: data.cellId,
        dashboardId: data.dashboardId,
        transactionId: data.transactionId,
        orderId: data.orderId,
        status: data.status,
    };
}
