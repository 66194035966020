import { ThemeProvider } from '@emotion/react';
import { FormControlLabel, Radio, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#29A4CC', // Голубой цвет
    },
  },
});

function CustomRadio(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel {...props} style={{ margin: '0px' }} control={<Radio color="primary" />} />
    </ThemeProvider>
  );
}
export default CustomRadio;
