import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {DashBoardDataInfoResponse} from "../backend/functions/board/board";
import {TileResponse} from "../backend/functions/tile/tile";

interface dashBoardReducer {
    dashBoardData: DashBoardDataInfoResponse | null;
    fundedPercentage: number;
}

const initialState: dashBoardReducer = {
    dashBoardData: null,
    fundedPercentage: 0,
};

const dashBoardSlice = createSlice({
    name: 'dashBoardReducer',
    initialState,
    reducers: {
        setDashBoardData(state, action: PayloadAction<DashBoardDataInfoResponse | null>) {
            state.dashBoardData = action.payload;
        },
        setTilesData(state, action: PayloadAction<TileResponse[] | null>) {
            state.dashBoardData = { dashBoardData: state.dashBoardData?.dashBoardData || null, tilesData: action.payload };
        },
        setFundedPercentage(state, action: PayloadAction<number>) {
            state.fundedPercentage = action.payload;
        },
    },
});

export const { setDashBoardData, setFundedPercentage, setTilesData } = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
