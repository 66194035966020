import React, { useState } from 'react';
import styles from '../styles/main.module.scss';
import hoverPolygon from '../assets/tileWithPlus.svg';
import useAppDispatch from '../hooks/use-app-dispatch';
import { setCurrentApprovedCell, setCurrentCellId } from '../store/main-reducer';
import { useMediaQuery } from '@mui/material';
import {StatusEnum, TileResponse, VariantEnum} from '../backend/functions/tile/tile';
import cx from 'classnames';

interface TileProps {
  polygon: string;
  centerIcon?: string | React.ReactNode;
  isCenteredTile: boolean;
  el: TileResponse;
}

const Tile: React.FC<TileProps> = ({ polygon, centerIcon, el, isCenteredTile }) => {
  const isSmallScreen = useMediaQuery('(max-width:991.98px)');
  const [hover, setHover] = useState<boolean>(false);
  const [isMoving, setIsMoving] = useState(false);

  const handleTouchStart = () => {
    setIsMoving(false);
  };

  const handleTouchMove = () => {
    setIsMoving(true);
  };

  const handleTouchEnd = (event: any, callback: () => void) => {
    event.preventDefault();
    if (!isMoving) {
      callback();
    }
    setIsMoving(false);
  };

  const dispatch = useAppDispatch();
  const getClearPolygon = () => {
    if (hover) {
      return hoverPolygon;
    } else return polygon;
  };

  const onClickTile = () => {
    if(isCenteredTile){
      const currentEl: TileResponse = {
        id: "",
        dashboard: '',
        cellId: '',
        memoryType: '',
        user: null,
        payment: null,
        tileCaption: '',
        variant: null,
        isCentered: true,
        paymentUrl: '',
        status: StatusEnum.APPROVED,
        logo: '',
        link: 'https://fundzzz.com/'
      }
      dispatch(setCurrentApprovedCell(currentEl));
    } else if(el.status === StatusEnum.APPROVED){
      dispatch(setCurrentApprovedCell(el));
    }
  }

  return (
    <div className={styles.cell__wrapper} onDragStart={(e) => e.preventDefault()}>
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={onClickTile}
        onClick={onClickTile}
        className={styles.cell}
        style={{ cursor: el.status === StatusEnum.APPROVED ||  isCenteredTile ? 'pointer' : 'auto' }}
      >
        {el.status === StatusEnum.EMPTY && !isCenteredTile ? (
          <>
            <img
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={(event) => handleTouchEnd(event, () => dispatch(setCurrentCellId(el.id)))}
              onClick={() => dispatch(setCurrentCellId(el.id))}
              style={{ cursor: 'pointer' }}
              className={styles.cell__image}
              src={getClearPolygon()}
              alt="Polygon"
            />
          </>
        ) : (
          <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <img className={styles.cell__image} src={polygon} alt="Polygon" />
            {centerIcon && (
                <>
                  <div className={cx(styles.cell__center, { [styles.centerTile]: isCenteredTile })}>{centerIcon}</div>
                </>
            )}
          </div>
        )}

        {/*{centerIcon && (
          <>
            <div className={styles.cell__center}>{centerIcon}</div>
          </>
        )}*/}
      </div>
      {!isSmallScreen && hover && el.status === StatusEnum.EMPTY && !isCenteredTile && (
        <div className={styles.tooltip}>
          <div className={styles.tooltip__content}>
            <div className={styles.tooltip__text}>Click here to donate $1,000</div>
            <div className={styles.tooltip__triangle}></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Tile;
