import React from 'react';
import styles from '../../styles/creation-form.module.scss';
import CustomField from '../CustomField';
import {KeysCreationForm} from "../../models/ICreationForm";

interface ThirdStepProps {
  form: { name: string; email: string };
  error: { name: string; email: string };
  handleInput: (e: React.ChangeEvent<HTMLInputElement>, field: KeysCreationForm) => void;
}

const ThirdStep: React.FC<ThirdStepProps> = ({ form, handleInput, error }) => {
  return (
    <div className={styles.contactInformation}>
      <h3 className={styles.label}>Please provide your contact information</h3>
      <CustomField
        isError={error.name}
        onChange={(e) => handleInput(e, 'name')}
        value={form.name}
        placeholder="Your Name"
        label="Name"
        isRequired={true}
        marginBottom="32px"
      />
      <CustomField
        isError={error.email}
        onChange={(e) => handleInput(e, 'email')}
        value={form.email}
        placeholder="Your Email"
        label="Email"
        isRequired={true}
        marginBottom="32px"
      />

      <div className={styles.confirmationText}>
        Please confirm your donation amount of <span>$1000.00</span> by clicking on the “Submit” button and you will be redirected to the payment page.
      </div>
    </div>
  );
};

export default ThirdStep;
