import {TileResponse} from "../backend/functions/tile/tile";

export const distributeItems = (items?: TileResponse[] | null, oddQty?: number, evenQty?: number) => {
  const columns: TileResponse[][] = [];

  if(!items) return columns;

  let columnIndex = 0;
  const specialItemIndex = items.findIndex(item => item.cellId === '74');
  const specialItem = items[specialItemIndex];

  items.forEach((item, index) => {
    if (!columns[columnIndex]) {
      columns[columnIndex] = [];
    }

    columns[columnIndex].push(item);

    if (
      (columnIndex % 2 === 0 && columns[columnIndex].length === oddQty) ||
      (columnIndex % 2 !== 0 && columns[columnIndex].length === evenQty)
    ) {
      columnIndex++;
    }
  });

  if (specialItem) {
    const centerColumnIndex = Math.floor(columns.length / 2);
    const centerColumn = columns[centerColumnIndex];
    const centerPosition = Math.floor(centerColumn.length / 2);

    const originalCenterItem = centerColumn[centerPosition];

    centerColumn[centerPosition] = specialItem;

    let insertColumnIndex = 0;
    let insertPosition = 0;
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      for (let j = 0; j < column.length; j++) {
        if (column[j].cellId === '74') {
          insertColumnIndex = i;
          insertPosition = j;
          break;
        }
      }
      if (insertColumnIndex || insertPosition) break;
    }

    columns[insertColumnIndex][insertPosition] = originalCenterItem;
  }

  return columns;
};
