import React, {useEffect, useRef, useState} from 'react';
import styles from '../styles/main.module.scss';
import Draggable from 'react-draggable';
import background from '../assets/backgroundHouse.png';
import useAppSelector from '../hooks/use-app-selector';
import Tile from './Tile';

import { useMediaQuery } from '@mui/material';
import {getDefaultPolygon, getPolygon} from '../helpers/getPolygon';
import {getContent, getFundzzzLogo} from '../helpers/getTileContent';
import { distributeItems } from '../helpers/distributeItems';
import { TileResponse } from '../backend/functions/tile/tile';

interface TilesGridProps {
  isHiddenGrid: boolean;
}
interface ColumnProps {
  columnIndex: number;
  totalColumns: TileResponse[][];
  data: TileResponse[];
}

const TilesGrid: React.FC<TilesGridProps> = ({ isHiddenGrid }) => {
  const { zoom } = useAppSelector((state) => state.mainReducer);
  const { dashBoardData } = useAppSelector((state) => state.dashBoardReducer);
  const [columnSizes, setColumnSizes] = useState<number[]>([7, 8]);

  const mediumLaptop = useMediaQuery('(max-width: 1400px)');
  const smallLaptop = useMediaQuery('(max-width: 1200px)');
  const tablet = useMediaQuery('(max-width: 991.98px)');
  const mobile = useMediaQuery('(max-width: 700.98px)');

  const tilesData = dashBoardData?.tilesData;

  useEffect(() => {
    if (mobile) {
      setColumnSizes([12, 13]);
    } else if (tablet) {
      setColumnSizes([12, 13]);
    } else if (smallLaptop) {
      setColumnSizes([10, 10]);
    } else if (mediumLaptop) {
      setColumnSizes([8, 9]);
    } else {
      setColumnSizes([7, 8]);
    }
  }, [smallLaptop, mediumLaptop, tablet, mobile]);

  const columns = distributeItems(tilesData, columnSizes[0], columnSizes[1]);

  return (
    <>
      <div className={styles.background}/>
      {zoom > 1 ? (
        <div style={{ transform: `scale(${zoom})` }}>
          <Draggable>
            <div>
              <div className={styles.items}>
                {!isHiddenGrid && (
                  <>
                    {columns.map((el, index) => (
                      <Column key={index} data={el} columnIndex={index} totalColumns={columns} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </Draggable>
        </div>
      ) : (
        <div>
          <div className={styles.items}>
            {!isHiddenGrid && (
              <>
                {columns.map((el, index) => (
                  <Column key={index} data={el} columnIndex={index} totalColumns={columns} />
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default TilesGrid;

const Column: React.FC<ColumnProps> = ({ data, columnIndex, totalColumns }) => {
  const exactCenterColumnIndex = Math.floor(totalColumns.length / 2);
  const isCenterColumn = columnIndex === exactCenterColumnIndex;

  const centerTileIndex = isCenterColumn ? Math.floor(data.length / 2) : null;


  function generateArithmeticProgression(start: number, difference: number, n: number): number[] {
    const progression = [];
    for (let i = 0; i < n; i++) {
      progression.push(start + i * difference);
    }
    return progression;
  }
  const progression = generateArithmeticProgression(1, 3, 20);

  const pos = progression[columnIndex];

  return (
    <div style={{ gridArea: `1/${pos} / span 1 / span 4` }} className={styles.column}>
      {data.map((el, index) => (
        <Tile isCenteredTile={isCenterColumn && index === centerTileIndex} el={el} key={el.id} polygon={isCenterColumn && index === centerTileIndex ? getDefaultPolygon() : getPolygon(el)} centerIcon={isCenterColumn && index === centerTileIndex ? getFundzzzLogo() : getContent(el)} />
      ))}
    </div>
  );
};
