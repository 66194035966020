import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum SnackBarType {
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface SnackBarTypeProps {
    message: string;
    type: SnackBarType;
}

interface snackBarReducer {
    message: string;
    type: SnackBarType | null;
}

const initialState: snackBarReducer = {
    message: '',
    type: null,
};

const snackBarSlice = createSlice({
    name: 'snackBarReducer',
    initialState,
    reducers: {
        setSnackBarMessage(state, action: PayloadAction<SnackBarTypeProps>) {
            const {message, type} = action.payload;
            state.message = message;
            state.type = type;
        },
        clearSnackBarMessage(state) {
            state.message = '';
            state.type = null;
        },
    },
});

export const {setSnackBarMessage, clearSnackBarMessage} = snackBarSlice.actions;

export default snackBarSlice.reducer;
