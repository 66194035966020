import React from 'react';
import styles from '../styles/preview-popup.module.scss';
import { ButtonBase } from '@mui/material';
import useAppSelector from '../hooks/use-app-selector';
import useAppDispatch from '../hooks/use-app-dispatch';
import { setCurrentApprovedCell } from '../store/main-reducer';
import { TileData, VariantEnum } from '../backend/functions/tile/tile';
import { getContent } from '../helpers/getTileContent';

export function formatLink(url: string) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}



const PreviewPopup: React.FC = ({}) => {
  const { currentApprovedCell } = useAppSelector((state) => state.mainReducer);
  const cell = currentApprovedCell as TileData;
  const dispatch = useAppDispatch();

  const getBackgroundColor = () => {
    if (cell?.variant === VariantEnum.FIRST || cell?.variant === VariantEnum.SECOND) {
      return { backgroundColor: '#1C5365', color: '#fff' };
    }
    if (currentApprovedCell?.variant === VariantEnum.THIRD) {
      return { backgroundColor: '#05222B', color: '#fff' };
    }
    if (cell?.variant === VariantEnum.FOURTH) {
      return { backgroundColor: '#1C5365', color: '#fff' };
    }
    return { backgroundColor: '#fff', color: '#05222B' };
  };

  return (
    <div className={styles.popupBackground} onClick={() => dispatch(setCurrentApprovedCell(null))}>
      <div className={styles.popupContent} style={{ backgroundColor: getBackgroundColor().backgroundColor }} onClick={(e) => e.stopPropagation()}>
        <div className={styles.image}>{getContent(cell, true, true)}</div>
        <div style={{ color: getBackgroundColor().color }} className={styles.title}>
          {cell.variant === VariantEnum.THIRD && `${cell.memoryType} ${cell.tileCaption}`}
          {cell.variant === VariantEnum.FOURTH && 'Anonymous Donor'}
        </div>
        {cell?.link && (
          <a target="_blank" style={{ color: getBackgroundColor().color }} href={formatLink(cell.link)} className={styles.link}>
            {cell.link}
          </a>
        )}
        <ButtonBase
          sx={{
            borderRadius: '4px',
            overflow: 'hidden',
            border: `1px solid ${getBackgroundColor().color}`,
          }}
        >
          <div onClick={() => dispatch(setCurrentApprovedCell(null))} className={styles.button} style={{ color: getBackgroundColor().color }}>
            close
          </div>
        </ButtonBase>
      </div>
    </div>
  );
};

export default PreviewPopup;
