import React from 'react';
import styles from '../../styles/creation-form.module.scss';
import { RadioGroup, Radio } from '@mui/material';
import CustomRadio from '../CustomRadio';
import { VariantEnum } from '../../backend/functions/tile/tile';
import PreviewTileImage from './PreviewTileImage';

import whitePreviewPolygon from '../../assets/tileWhitePreview.svg';
import previewPolygon from '../../assets/tilePreview.svg';
import honorPolygon from '../../assets/tileMemory.svg';
import withoutImagePolygon from '../../assets/tileWithoutImage.svg';
import donationIcon from '../../assets/donate.svg';
import familyIcon from '../../assets/creation-form/family.svg';
import honorIcon from '../../assets/creation-form/honor.svg';
import treeIcon from '../../assets/creation-form/tree.svg';

interface FirstStepProps {
  variant: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FirstStep: React.FC<FirstStepProps> = ({ variant, handleChange }) => {
  return (
    <div>
      <h3 className={styles.label}>Please, select an option</h3>
      <div className={styles.grayLabel}>Examples of tiles depending on the selected option:</div>
      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={variant} onChange={handleChange}>
        <div className={styles.variants}>
          <div className={styles.variants__item}>
            <div className={styles.variants__image}>
              <PreviewTileImage backgroundImage={whitePreviewPolygon} />
            </div>
            <div className={styles.variants__text}>Add Company Logo and Webpage Link</div>
            <CustomRadio value={VariantEnum.FIRST} control={<Radio />} />
          </div>
          <div className={styles.variants__item}>
            <div className={styles.variants__image}>
              <PreviewTileImage backgroundImage={previewPolygon} />
            </div>
            <div className={styles.variants__text}>
              Add Picture <span>(of an Individual, Family or Anything Else)</span>
            </div>
            <CustomRadio value={VariantEnum.SECOND} control={<Radio />} />
          </div>
          <div className={styles.variants__item}>
            <div className={styles.variants__image}>
              <PreviewTileImage backgroundImage={honorPolygon} />
            </div>
            <div className={styles.variants__text}>
              Add an In-Honor or In-Memory Picture <span>(along with the name of your loved one)</span>
            </div>
            <CustomRadio value={VariantEnum.THIRD} control={<Radio />} />
          </div>
          <div className={styles.variants__item}>
            <div className={styles.variants__image}>
              <PreviewTileImage backgroundImage={withoutImagePolygon} />
            </div>
            <div className={styles.variants__text}>
              Anonymous <br />
              Donation
            </div>
            <CustomRadio value={VariantEnum.FOURTH} control={<Radio />} />
          </div>
        </div>
      </RadioGroup>
    </div>
  );
};

export default FirstStep;
