import tileEmpty from '../../assets/tileEmpty.svg';
import tileMemory from '../../assets/tileMemory.svg';
import tileWithoutImage from '../../assets/tileWithoutImage.svg';
import React, { useEffect, useState } from 'react';
import styles from '../../styles/creation-form.module.scss';
import { ButtonBase, Radio, RadioGroup, Typography } from '@mui/material';
import CustomField from '../CustomField';
import CustomRadio from '../CustomRadio';
import { VariantEnum } from '../../backend/functions/tile/tile';
import PreviewTileImage from './PreviewTileImage';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import {KeysCreationForm} from "../../models/ICreationForm";
import {formatLink} from "../PreviewPopup";
import heic2any from "heic2any";
import {convertHEICToJPEG} from "./utils/utils";

interface SecondStepProps {
  form: { link: string; text: string };
  error: { link: string; text: string; file: string };
  handleInput: (e: React.ChangeEvent<HTMLInputElement>, field: KeysCreationForm ) => void;
  variant: string;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setTileText: React.Dispatch<React.SetStateAction<string>>;
  tileText: string;
}

const SecondStep: React.FC<SecondStepProps> = ({ form, handleInput, variant, file, setFile, setTileText, tileText, error }) => {
  const handleDeleteFile = () => {
    setFile(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTileText(event.target.value);
  };

  return (
    <div>
      {variant === VariantEnum.FIRST && (
        <div className={styles.firstVariant}>
          <h3 className={styles.label}>Upload a picture or logo</h3>
          <div className={styles.bodyContainer}>
            <div className={styles.leftPart}>
              <UploadFile error={error} setFile={setFile} file={file} />
              <div className={styles.yourLink}>
                <h3 className={styles.label}>Your webpage link</h3>
                <CustomField
                  isError={error.link}
                  onChange={(e) => handleInput(e, 'link')}
                  value={form.link}
                  placeholder="Your link"
                  label="Link"
                  marginBottom="12px"
                />
                {form.link && (
                  <a target="_blank" href={formatLink(form.link)} className={styles.testLinkLabel}>
                    Test your link
                  </a>
                )}
              </div>
            </div>
            <PreviewTile backgroundImage={tileEmpty} file={file} handleDeleteFile={handleDeleteFile} />
          </div>
        </div>
      )}
      {variant === VariantEnum.SECOND && (
        <div>
          <div className={styles.secondVariant}>
            <div className={clsx(styles.bodyContainer, styles.bodyContainer_second)}>
              <div className={styles.leftPart}>
                <h3 className={styles.label}>Upload a picture</h3>
                <UploadFile error={error} setFile={setFile} file={file} />
              </div>
              <PreviewTile backgroundImage={tileEmpty} file={file} handleDeleteFile={handleDeleteFile} />
            </div>
          </div>
        </div>
      )}
      {variant === VariantEnum.THIRD && (
        <div>
          <div className={styles.bodyContainer}>
            <div className={styles.leftPart}>
              <h3 className={styles.label}>Upload a picture</h3>
              <UploadFile error={error} setFile={setFile} file={file} />
              <RadioGroup
                sx={{ marginBottom: '20px' }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={tileText}
                onChange={handleChange}
              >
                <CustomRadio value={'In Honor Of'} control={<Radio />} label="In Honor Of" />
                <CustomRadio value={'In Memory Of'} control={<Radio />} label="In Memory Of" />
              </RadioGroup>
              <h3 className={styles.label}>Name of Individual</h3>
              <CustomField
                isError={error.text}
                onChange={(e) => handleInput(e, 'text')}
                value={form.text}
                placeholder="Your Name"
                label="Name"
                marginBottom="0px"
              />
            </div>
            <PreviewTile backgroundImage={file ? tileEmpty : tileMemory} file={file} handleDeleteFile={handleDeleteFile} />
          </div>
        </div>
      )}
      {variant === VariantEnum.FOURTH && (
        <div className={styles.fourthVariant}>
          <div className={styles.previewTitle}>Preview of your tile:</div>
          <PreviewTileImage backgroundImage={tileWithoutImage} />
        </div>
      )}
    </div>
  );
};

export default SecondStep;

interface UploadFileProps {
  error: { link: string; text: string; file: string };
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}
const UploadFile: React.FC<UploadFileProps> = ({ file, setFile, error }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <div className={styles.uploadFile}>
      {!file ? (
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <ButtonBase
            sx={{
              borderRadius: '4px',
              overflow: 'hidden',
              marginBottom: '6px',
            }}
          >
            <div className={styles.buttonWrapper}>
              <label htmlFor="upload" className={styles.buttonLabel}>
                Choose File
              </label>
              <input id="upload" type="file" className={styles.fileInput} onChange={handleFileChange} />
            </div>
          </ButtonBase>
          {!!error.file && (
            <Typography color={'#d32f2f'} sx={{ ml: 2, fontSize: '0.75rem' }}>
              File is required
            </Typography>
          )}
        </div>
      ) : (
        <div className={styles.delete}>
          <div className={styles.delete__name}>{file.name}</div>
        </div>
      )}
    </div>
  );
};

interface PreviewTileProps {
  backgroundImage: string;
  handleDeleteFile: () => void;
  file: File | null;
}
const PreviewTile: React.FC<PreviewTileProps> = ({ backgroundImage, handleDeleteFile, file }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleFileChange = async () => {
    if (file?.type === "image/heic") {
      const previewUrl = await convertHEICToJPEG(file);
      setPreviewUrl(previewUrl);
    } else if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);
    } else setPreviewUrl(null);
  };

  useEffect(() => {
    handleFileChange();
  }, [file]);

  return (
    <div className={styles.preview}>
      <div className={styles.previewTitle}>Preview of your tile:</div>
      {file ? (
        <>
          <div style={{ marginBottom: '16px' }}>
            <PreviewTileImage isPreview={!!previewUrl} backgroundImage={backgroundImage} centerImage={previewUrl} />
          </div>
          <ButtonBase
            onClick={handleDeleteFile}
            sx={{
              borderRadius: '4px',
              overflow: 'hidden',
              display: 'flex',
              columnGap: '8px',
              padding: '8px 16px',
              alignItems: 'center',
              border: '1px solid #e39596',
            }}
          >
            <div className={styles.delete__icon}>
              <ClearIcon />
            </div>
            <div className={styles.delete__label}>delete</div>
          </ButtonBase>
        </>
      ) : (
        <PreviewTileImage backgroundImage={backgroundImage} />
      )}
    </div>
  );
};
