import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/layout.module.scss';
import logo from '../../assets/logo.svg';
import useAppSelector from '../../hooks/use-app-selector';
import helpIcon from '../../assets/helpIcon.svg';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { setHelpPopupOpen } from '../../store/main-reducer';
import { addDashboard } from '../../backend/functions/board/board';
import tree from '../../assets/creation-form/tree.svg';
import burgerIcon from '../../assets/burgerIcon.svg';
import { createTiles, createUserAndPaymentAndUpdateTile, getTilesByDashboardId, VariantEnum } from '../../backend/functions/tile/tile';
import clsx from 'clsx';
import { ButtonBase, useMediaQuery } from '@mui/material';

const Header: React.FC = () => {
  const { dashBoardData, fundedPercentage } = useAppSelector((state) => state.dashBoardReducer);
  const isMobile = useMediaQuery('(max-width: 700.98px)');
  const isTablet = useMediaQuery('(max-width: 991.98px)');
  const dispatch = useAppDispatch();
  const [isOpenSpoiler, setOpenSpoiler] = useState<boolean>(false);

  const handleOpenSpoiler = () => {
    setOpenSpoiler((current) => !current);
  };

  const dashBoardInfo = dashBoardData?.dashBoardData;

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const cleanWebsiteUrl = (url?: string) => {
    let cleanedUrl = url?.replace(/^(https?:\/\/)?(www\.)?/, '');

    cleanedUrl = cleanedUrl?.replace(/\/$/, '');

    cleanedUrl = cleanedUrl?.replace(/\//g, '');

    return cleanedUrl;
  };

  const handleCreateDashboard = async () => {
    try {
      await addDashboard();
      console.log('Dashboard added successfully!');
    } catch (error) {
      console.error('Failed to add dashboard:', error);
    }
  };

  const handleCreateTiles = async () => {
    try {
      console.log('I am here');
      await createTiles('X5vOh9rlpXzqcl0xkfK4');
      console.log('Tiles added successfully!');
    } catch (error) {
      console.error('Failed to add Tiles:', error);
    }
  };

  const handleGetTilesByDashboardId = async () => {
    try {
      const data = await getTilesByDashboardId('X5vOh9rlpXzqcl0xkfK4');
      console.log('Get Tile By Dashboard get successfully!', data);
    } catch (error) {
      console.error('Failed to Get Tile By Dashboard:', error);
    }
  };

 /* const handleUpdateTileById = async () => {
    try {
      const data = await createUserAndPaymentAndUpdateTile(
        { email: 'yariks2401@gmail.com', name: 'Yaroslav', dashboardId: 'X5vOh9rlpXzqcl0xkfK4' },
        {
          cellId: '103',
          dashboard: 'X5vOh9rlpXzqcl0xkfK4',
          link: 'test',
          logo: 'test',
          tileCaption: 'test',
          variant: VariantEnum.FIRST,
        },
        '0YOo3soYcQhgCBkuIfiI'
      );
      console.log('Updated Tile By Id successfully!', data);
    } catch (error) {
      console.error('Failed Updated Tile By Id:', error);
    }
  };*/

  return (
    <header className={styles.header}>
     {/* <div onClick={handleCreateDashboard}>AddDashboard</div>
      <div onClick={handleCreateTiles}>Create Tiles</div>
      <div onClick={handleGetTilesByDashboardId}>Get Tiles</div>*/}
      <div className={styles.top}>
        <div className={styles.logo}>
          <img height='64px' src={logo} alt="Company Logo" />
        </div>
        <div className={styles.rightPart}>
          {!isTablet && (
            <button onClick={() => dispatch(setHelpPopupOpen(true))} className={styles.helpButton}>
              <div className={styles.helpButton__icon}>
                <img src={helpIcon} alt="Help Icon" />
              </div>
              <div className={styles.helpButton__label}>HELP</div>
            </button>
          )}
          {isTablet && <div className={styles.burger}>
            <ButtonBase onClick={handleOpenSpoiler} sx={{ p: 1, width: '50px', height: '50px', borderRadius: '50%', overflow: 'hidden' }}>
              <div className={styles.burgerIcon}>
                <img src={burgerIcon} alt="Burger Icon" />
              </div>
            </ButtonBase>
          </div>}
        </div>
      </div>
      {isTablet && isOpenSpoiler && (
        <div style={{ transition: 'all 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button onClick={() => dispatch(setHelpPopupOpen(true))} className={styles.helpButton}>
            <div className={styles.helpButton__icon}>
              <img src={helpIcon} alt="Help Icon" />
            </div>
            <div className={styles.helpButton__label}>HELP</div>
          </button>
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.company}>
          <div className={styles.company__logo}>
            <img src={tree} alt="Company Logo" />
          </div>
          <div className={styles.company__text}>{String('Providence Farm Charity').toUpperCase()}</div>
        </div>
        <div className={styles.body__right}>
          <div className={styles.body__top}>
            {!isMobile && (
              <div className={clsx(styles.infoItem, styles.infoItem__progressbar)}>
                <div className={styles.infoItem__name}>Tiles Funded</div>
                <div className={styles.progressbar}>
                  <div
                    className={styles.progressbar__line}
                    style={{ background: `linear-gradient(to right, #2490B3 ${fundedPercentage}%, #fff ${fundedPercentage}%)` }}
                  ></div>
                  <div className={styles.progressbar__number}>{fundedPercentage}%</div>
                </div>
              </div>
            )}
            <div className={styles.infoItem}>
              <div className={styles.infoItem__name}>Name of Wall</div>
              <div className={styles.infoItem__label}>{dashBoardInfo?.name_wall || '--'}</div>
            </div>
            {isMobile && (
              <div className={styles.infoItem}>
                <div className={styles.infoItem__name}>Raise Amount</div>
                <div className={styles.infoItem__label}>{formatCurrency(dashBoardInfo?.raise_amount || 0)}</div>
              </div>
            )}
          </div>
          <div className={styles.body__bottom}>
            {isMobile && (
              <div className={clsx(styles.infoItem, styles.infoItem__progressbar)}>
                <div className={styles.infoItem__name}>Tiles Funded</div>
                <div className={styles.progressbar}>
                  <div
                    className={styles.progressbar__line}
                    style={{ background: `linear-gradient(to right, #2490B3 ${fundedPercentage}%, #fff ${fundedPercentage}%)` }}
                  ></div>
                  <div className={styles.progressbar__number}>{fundedPercentage}%</div>
                </div>
              </div>
            )}
            {!isMobile && (
              <div className={styles.infoItem}>
                <div className={styles.infoItem__name}>Raise Amount</div>
                <div className={styles.infoItem__label}>{formatCurrency(dashBoardInfo?.raise_amount || 0)}</div>
              </div>
            )}
            <div className={styles.infoItem}>
              <div className={styles.infoItem__name}>Website</div>
              <div className={styles.infoItem__label}>{cleanWebsiteUrl(dashBoardInfo?.website) || '--'}</div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
