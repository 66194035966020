import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface loaderReducer {
    isLoading: boolean;
}

const initialState: loaderReducer = {
    isLoading: false,
};

const loaderSlice = createSlice({
    name: 'loaderReducer',
    initialState,
    reducers: {
        setIsGeneralLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

export const {setIsGeneralLoading} = loaderSlice.actions;

export default loaderSlice.reducer;
