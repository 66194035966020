import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../db";
import {createTileFromDoc} from "./utils";

export interface User {
    email: string;
    name: string;
    dashboardId: string;
}

export interface UserResponse {
    id: string;
    email: string;
    name: string;
    dashboardId: string;
}

export const addUser = async (userInfo: User): Promise<UserResponse> => {
    try {
        const userRef = await addDoc(collection(db, "users"), {
            email: userInfo.email,
            name: userInfo.name,
            dashboardId: userInfo.dashboardId,
        });
        return {
            ...userInfo,
            id: userRef.id
        };
    } catch (e) {
        throw e;
    }
};

export const getUsersByDashboardId = async (dashboardId: string): Promise<UserResponse[]> => {
    try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("dashboardId", "==", dashboardId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(createTileFromDoc);
    } catch (e) {
        throw e;
    }
};
