import React, { useEffect, useState } from 'react';
import styles from '../../styles/layout.module.scss';
import {Outlet, useLocation} from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import useAppSelector from '../../hooks/use-app-selector';
import CreationForm from '../CreationForm/CreationForm';
import HelpForm from '../HelpForm';
import WelcomeVideo from '../WelcomeVideo';
import PreviewPopup from '../PreviewPopup';
import {getDashBoardDataById} from "../../backend/functions/board/board";
import {useDispatch} from "react-redux";
import {setDashBoardData, setFundedPercentage, setTilesData} from "../../store/dashBoard-reducer";
import {StatusEnum, subscribeToTilesByDashboardId} from "../../backend/functions/tile/tile";
import {setIsGeneralLoading} from "../../store/fullScreenLoader";
import {SnackBarType} from "../../store/snackBar";
import useSnackbar from "../../hooks/useSnackBar";

const Layout: React.FC = () => {
  const { currentCellId, isHelpPopupOpen, currentApprovedCell } = useAppSelector((state) => state.mainReducer);
  const { dashBoardData } = useAppSelector((state) => state.dashBoardReducer);
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(false);
  const location = useLocation();
  const updateSnackBar = useSnackbar();

  const dispatch = useDispatch();

  const tilesData = dashBoardData?.tilesData;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success') === 'true';
    if(!success){
      const isOpenWelcomePopup = sessionStorage.getItem('isOpenWelcomePopup');
      if (isOpenWelcomePopup === null || isOpenWelcomePopup === 'true') {
        setIsWelcomePopupOpen(true);
      }
    }
    dispatch(setIsGeneralLoading(true));
    getDashBoardDataById('X5vOh9rlpXzqcl0xkfK4').then(currentDashBoardData => {
      dispatch(setDashBoardData(currentDashBoardData));
    }).finally(() => {
      dispatch(setIsGeneralLoading(false));
    }).catch(() => {
      updateSnackBar({message: 'Something went wrong, please try again later', type: SnackBarType.ERROR})
    });

    const unsubscribe = subscribeToTilesByDashboardId('X5vOh9rlpXzqcl0xkfK4', (tiles) => {
      dispatch(setTilesData(tiles));
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (tilesData) {
      let emptyNumber: number = 0;

      tilesData.forEach((el) => {
        if (el.status === StatusEnum.EMPTY) {
          return (emptyNumber += 1);
        }
      });
      const percentage = 100 - Math.round((emptyNumber / tilesData.length) * 100);
      dispatch(setFundedPercentage(percentage));
    }
  }, [tilesData]);

  const closePopup = () => {
    sessionStorage.setItem('isOpenWelcomePopup', 'false');
    setIsWelcomePopupOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ height: currentCellId ? '100vh' : 'auto', overflow: currentCellId ? 'hidden' : 'auto' }} className={styles.container}>
        <Header />
        <main className={styles.main}>
          <Outlet />
        </main>
        <Footer />
        {currentApprovedCell && <PreviewPopup />}
        {currentCellId && <CreationForm />}
        {isHelpPopupOpen && <HelpForm />}
        {isWelcomePopupOpen && <WelcomeVideo setPopupClose={() => closePopup()} />}
      </div>
    </div>
  );
};
export default Layout;
