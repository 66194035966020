import React from 'react';
import Snackbar, {SnackbarCloseReason} from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import {clearSnackBarMessage} from "../../store/snackBar";
import {RootState} from "../../store";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

export const CustomSnackbar: React.FC = () => {
    const dispatch = useDispatch();
    const { message, type } = useSelector((state: RootState) => state.snackBarReducer);
    const open = Boolean(message);


    const handleClose = (
        event: React.SyntheticEvent<Element, Event> | Event,
        reason: SnackbarCloseReason
    ) => {
        if (reason !== 'clickaway') {
            dispatch(clearSnackBarMessage());
        }
    };

    const backgroundColors = {
        success: '#2E7D32',
        error: '#D32F2F',
    };


    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            key={'bottomright'}
        >
            <Alert
                // @ts-ignore
                onClose={handleClose}
                severity={type!}
                iconMapping={{
                    success: <span style={{ color: '#FFFFFF' }}>✓</span>,
                    error: <span style={{ color: '#FFFFFF' }}>✕</span>,
                }}
                sx={{
                    backgroundColor: backgroundColors[type!],
                    color: '#FFFFFF',
                    '.MuiAlert-icon': { color: '#FFFFFF' },
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
