import React from 'react';
import styles from '../styles/popup.module.scss';
import { ButtonBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UnknownAction } from '@reduxjs/toolkit';

interface PopupProps {
  children: React.ReactNode;
  setClose: () => UnknownAction | void
}

const Popup: React.FC<PopupProps> = ({ children, setClose }) => {

  return (
    <div className={styles.popupBackground} onClick={() => setClose()}>
      <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.crossIcon} onClick={() => setClose()}>
          <ButtonBase>
            <CloseIcon />
          </ButtonBase>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popup;
