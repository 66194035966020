import React, { useState, useEffect } from 'react';
import styles from '../../styles/creation-form.module.scss';
import { Box, ButtonBase, Step, StepIconProps, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import useAppDispatch from '../../hooks/use-app-dispatch';
import {ICreationForm, KeysCreationForm} from '../../models/ICreationForm';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress'
import Check from '@mui/icons-material/Check';
import FirstStep from './Step1';
import SecondStep from './Step2';
import ThirdStep from './Step3';
import Popup from '../Popup';
import { setCurrentCellId } from '../../store/main-reducer';
import useAppSelector from '../../hooks/use-app-selector';
import { createUserAndPaymentAndUpdateTile, TileResponse, VariantEnum } from '../../backend/functions/tile/tile';
import { uploadFileAndLinkToTile } from '../../backend/functions/attachment/attachment';
import { ICreationErrors } from '../../models/ICreationErrors';
import {SnackBarType} from "../../store/snackBar";
import useSnackbar from "../../hooks/useSnackBar";
import {validationRules} from "./utils/validationRules";
import {isValidEmail, isValidField, isValidFile, isValidName, isValidUrl} from "../../helpers/utils";

const steps = ['Choice of Option', 'Tile Settings', 'Contact Information'];

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;
  const [color, setColor] = useState<string>('#29A4CC');

  useEffect(() => {
    if (active) {
      setColor('#29A4CC');
    } else if (completed) {
      setColor('#A5D15C');
    } else setColor('#989D9E');
  }, [active, completed]);

  return (
    <Box
      sx={{
        backgroundColor: color,
        color: '#F5FDFF',
        fontSize: '12px',
        borderRadius: '50%',
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '.MuiStepIcon-text': {
          fill: '#F5FDFF',
        },
      }}
    >
      {completed ? <Check sx={{ width: '14px', height: '14px' }} /> : icon}
    </Box>
  );
}

const CreationForm: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [file, setFile] = useState<null | File>(null);
  const [form, setForm] = useState<ICreationForm>({ name: '', email: '', link: '', text: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ICreationErrors>({
    name: '',
    email: '',
    link: '',
    text: '',
    file: '',
  });
  const [variant, setVariant] = useState<VariantEnum>(VariantEnum.FIRST);
  const [tileText, setTileText] = useState<string>('In Honor Of');
  const dispatch = useAppDispatch();
  const { currentCellId } = useAppSelector((state) => state.mainReducer);
  const { dashBoardData } = useAppSelector((state) => state.dashBoardReducer);
  const isTablet = useMediaQuery('(max-width: 991.98px)');

  const updateSnackBar = useSnackbar();

  const validateForm = () => {
    const errors = {} as ICreationErrors;

    if (activeStep === 1) {

      if(variant === VariantEnum.FIRST) {
        if (!isValidField(form.link) && !isValidUrl(form.link)) {
          errors.link = 'Enter your website link in the format: www.yourwebsite.com';
        }
        if (!file) {
          errors.file = 'required';
        }
      } else if(variant === VariantEnum.SECOND) {
        if (!file) {
          errors.file = 'required';
        }
      } else if(variant === VariantEnum.THIRD) {
        if (!isValidField(form.text)) {
          errors.text = 'required';
        }
      }
    }

    if (activeStep === 2) {
      if (!isValidField(form.name)) {
        errors.name = 'required'
      } else if (!isValidName(form.name)) {
          errors.name = 'Must contain at least 3 characters'
        }


      if (!isValidField(form.email.toLowerCase())) {
        errors.email = 'required'
      } else if (!isValidEmail(form.email.toLowerCase())) {
        errors.email = 'Enter your email address in the format: yourname@example.com';
      }
    }

    if (Object.values(errors).length > 0) {
      setError((current) => ({ ...current, ...errors }));
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if(activeStep === 0) {
      setFile(null);
      setForm({ name: '', email: '', link: '', text: '' });
      setError({ name: '', email: '', link: '', text: '', file: '' });
    }
    if (!validateForm()) {
      return;
    }
    setError({ name: '', email: '', link: '', text: '', file: '' });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleVariantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariant((event.target as HTMLInputElement).value as VariantEnum);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, field: KeysCreationForm) => {
    const currentValue = e.target.value;
    const fieldRules = validationRules[field];
    let currentError = "";

    if (fieldRules) {
      for (const rule of fieldRules) {
        if (!rule.rule(currentValue)) {
          currentError = rule.message;
          break;
        }
      }
    }
    setForm((current) => ({ ...current, [field]: e.target.value }));
    setError((currentErrors) => ({ ...currentErrors, [field]: currentError }))
  };

  const handleSubmitForm = async () => {
    if (!validateForm()) {
      return;
    }
    const currentCellData = dashBoardData?.tilesData?.find((currentTile: TileResponse) => currentTile.id === currentCellId);
    if (!currentCellData) return;
    try {
      let paymentUrl = '';
      setIsLoading(true)
      let imgUrl = '';
      if (file) {
        imgUrl = await uploadFileAndLinkToTile(file, currentCellData.id);
      }
      const response: any = await fetch('https://us-central1-fundzzz-3c9d7.cloudfunctions.net/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: form.email.toLowerCase(),
          cellId: currentCellData.cellId,
          id: currentCellData.id,
          dashBoardId: currentCellData.dashboard,
        }),
      });
      const currentSessionData = await response.json();
      if (currentSessionData) {
        paymentUrl = currentSessionData.url;
        await createUserAndPaymentAndUpdateTile(
        { email: form.email.toLowerCase(), name: form.name, dashboardId: 'X5vOh9rlpXzqcl0xkfK4' },
        {
          cellId: currentCellData.cellId,
          dashboard: currentCellData.dashboard,
          memoryType: variant === VariantEnum.THIRD ? tileText : '',
          link: form.link,
          logo: imgUrl,
          tileCaption: form.text,
          variant,
          paymentUrl: currentSessionData.session.id
        },
        currentCellData.id
      );
      }
      if(paymentUrl) {
        window.location.href = currentSessionData.url;
        dispatch(setCurrentCellId(null));
      }
    } catch (err) {
      updateSnackBar({message: 'Something went wrong, please try again later', type: SnackBarType.ERROR})
    } finally {
      setIsLoading(true)
    }
  };

  return (
    <Popup setClose={() => dispatch(setCurrentCellId(null))}>
      <div className={styles.body}>
        <h2 className={styles.title}>{activeStep !== steps.length ? 'Tile Reservation' : 'Successful reservation'}</h2>
        <Box sx={{ width: '100%' }}>
          {!isTablet && (
            <Stepper sx={{ marginBottom: '32px' }} activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          {activeStep === steps.length ? (
            <div className={styles.thank}>
              <h3 className={styles.thank__text}>Thanks for the donation!</h3>
              <p className={styles.thank__article}>
                You have successfully reserved a tile. When administrator confirms your donation, your tile will appear on the donor wall. If the donation is
                rejected, the money will automatically returned tou you.
              </p>
            </div>
          ) : (
            <>
              {activeStep === 0 && <FirstStep variant={variant} handleChange={handleVariantChange} />}
              {activeStep === 1 && (
                <SecondStep
                  error={error}
                  file={file}
                  setFile={setFile}
                  form={form}
                  handleInput={handleInput}
                  tileText={tileText}
                  setTileText={setTileText}
                  variant={variant}
                />
              )}
              {activeStep === 2 && <ThirdStep error={error} handleInput={handleInput} form={form} />}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '110px' }}>
                  {activeStep !== 0 && (
                    <ButtonBase
                      sx={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                        border: '1px solid #29a4cc',
                      }}
                      color="inherit"
                      onClick={handleBack}
                    >
                      <div className={styles.backButton}>
                        <div>
                          <ArrowBackIosIcon />
                        </div>
                        <div>Back</div>
                      </div>
                    </ButtonBase>
                  )}
                </div>

                <div className={styles.steps}>{activeStep + 1}/3</div>
                <ButtonBase sx={{ borderRadius: '4px', overflow: 'hidden' }}>
                  {activeStep === steps.length - 1 ? (
                    <div onClick={!isLoading ? handleSubmitForm : (e) => e.preventDefault()}  style={{ padding: '13px 22px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', cursor: isLoading ? 'default' : 'pointer' }} className={styles.blueButton}>
                      {isLoading ? <CircularProgress size={24} style={{ color: '#FFF' }} /> : 'Submit'}
                    </div>
                  ) : (
                    <div className={styles.blueButton} onClick={handleNext}>
                      <div className={styles.nextButton__label}>Next</div>
                      <div className={styles.nextButton__icon}>
                        <NavigateNextIcon />
                      </div>
                    </div>
                  )}
                </ButtonBase>
              </Box>
            </>
          )}
        </Box>
      </div>
    </Popup>
  );
};
export default CreationForm;
