import { QueryDocumentSnapshot } from 'firebase/firestore';
import {UserResponse} from "./user";

export const createTileFromDoc = (doc: QueryDocumentSnapshot): UserResponse => {
    const data = doc.data();

    return {
        id: doc.id,
        email: data.email,
        name: data.name,
        dashboardId: data.dashboardId
    };
}
