import charityIcon from '../assets/donate.svg';
import fundzzIcon from '../assets/logo.svg';
import treeIcon from '../assets/anonymousIcon.svg'
import memoryIcon from '../assets/memoryIcon.svg'
import { StatusEnum, TileData,  VariantEnum } from '../backend/functions/tile/tile';
import { handleImageError } from './handleImageError';

export const getFundzzzLogo = () => {
  return <img src={fundzzIcon} alt="logo" />;
}

export const getContent = (el: TileData, isPopup: boolean = false, isCentered: boolean = true) => {
  if (el.status === StatusEnum.RESERVED) {
    return 'Reserved';
  }
  if (el.variant === VariantEnum.FOURTH) {
    return <img src={treeIcon} alt="logo" />;
  }
  if (el.status === StatusEnum.APPROVED && el?.isCentered && isPopup && isCentered){
    return getFundzzzLogo();
  }
  if (el.variant === VariantEnum.THIRD && !el.logo && isPopup){
    return <img src={memoryIcon} alt="logo" />;
  }
  if (el.variant === VariantEnum.THIRD && !el.logo) {
    return null;
  }
  if (el.status === StatusEnum.APPROVED) {
    const imageSrc = el.logo ? el.logo : charityIcon;

    return <img onError={handleImageError} src={imageSrc} alt="logo" />;
  }

  return null;
};
