import React, {useState} from 'react';
import Popup from './Popup';
import styles from '../styles/help-form.module.scss';
import CustomField from './CustomField';
import {IHelpForm} from '../models/IHelpForm';
import {Box} from '@mui/material';
import {setHelpPopupOpen} from '../store/main-reducer';
import useAppDispatch from '../hooks/use-app-dispatch';
import BlueButton from './BlueButton';
import {EMAIL_REG_EXP} from '../helpers/email-regular-exp';
import {isTrimmedEmpty} from '../helpers/isTrimmedEmpty';
import useSnackbar from "../hooks/useSnackBar";
import {SnackBarType} from "../store/snackBar";

const HelpForm: React.FC = () => {
  const [form, setForm] = useState<IHelpForm>({ name: '', email: '', issue: '' });
  const [error, setError] = useState<IHelpForm>({ name: '', email: '', issue: '' });
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const updateSnackBar = useSnackbar();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setForm((current) => ({ ...current, [field]: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors: IHelpForm = {name: '', email: '', issue: ''};

    if (isTrimmedEmpty(form.name)) errors.name = 'required';
    if (isTrimmedEmpty(form.email)) errors.email = 'required';
    if (isTrimmedEmpty(form.issue)) errors.issue = 'required';

    if (Object.keys(errors).length === 0) {
      if (!EMAIL_REG_EXP.test(form.email)) {
        errors.email = 'Enter your email address in the format: yourname@example.com';
      } else if (form.name.trim().length < 3) {
        errors.name = 'Must contain at least 3 characters';
      }
    }

    if (Object.values(errors).some(err => !!err)) {
      setError((current) => ({...current, ...errors}));
    } else {
      setError({name: '', email: '', issue: ''});
      try {
        setIsLoading(true);
        await fetch('https://us-central1-fundzzz-3c9d7.cloudfunctions.net/api/report-issue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: form.name,
            email: form.email,
            issue: form.issue,
          }),
        });
        dispatch(setHelpPopupOpen(false));
        updateSnackBar({message: 'Form sent succesfully', type: SnackBarType.SUCCESS})
      } catch (e) {
        updateSnackBar({message: 'Something went wrong, please try again later', type: SnackBarType.ERROR})
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Popup setClose={() => dispatch(setHelpPopupOpen(false))}>
      <form onSubmit={(e) => handleSubmit(e)} className={styles.form}>
        <h2 className={styles.title}>Help Form</h2>
        <Box sx={{ marginBottom: '32px' }}>
          <CustomField isError={error.name} value={form.name} isRequired={true} placeholder="Your name" label="Name" onChange={(e) => handleInput(e, 'name')} />
        </Box>
        <Box sx={{ marginBottom: '32px' }}>
          <CustomField
            isError={error.email}
            value={form.email}
            isRequired={true}
            placeholder="Your email"
            label="Email"
            onChange={(e) => handleInput(e, 'email')}
          />
        </Box>
        <Box sx={{ marginBottom: '32px' }}>
          <CustomField
            isError={error.issue}
            rows={3}
            value={form.issue}
            isRequired={true}
            placeholder="Your Question/Issue/Comment"
            label="Issue"
            onChange={(e) => handleInput(e, 'issue')}
          />
        </Box>
        <BlueButton label="Send" type="submit" isLoading={isLoading} />
      </form>
    </Popup>
  );
};
export default HelpForm;
