import {addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where, collectionGroup} from "firebase/firestore";
import {db} from "../../db";
import {addUser, getUsersByDashboardId, User, UserResponse} from "../user/user";
import {getPaymentsByDashboardId, PaymentInfo, PaymentInfoResponse} from "../payment/payment";
import {createTileFromDoc} from "./utils";

export enum StatusEnum {
    RESERVED = 'Reserved',
    APPROVED = 'Completed',
    EMPTY = 'Open',
}

export enum VariantEnum {
    FIRST = 'first',
    SECOND = 'second',
    THIRD = 'third',
    FOURTH = 'fourth',
}



export interface TileData {
    dashboard: string;
    status: StatusEnum;
    cellId: string;
    memoryType: string;
    user: string | null;
    payment: string | null;
    link: string;
    logo: string;
    tileCaption: string;
    variant: VariantEnum | null;
    isCentered?: boolean;
    reservedAt?: string;
    paymentUrl: string;
}

export interface TileResponse extends TileData{
    id: string;
    paymentInfo?: PaymentInfo | null;
    userInfo?: User | null;
}


export const createTiles = async (dashboardId: string): Promise<void> => {
    const promises: Promise<any>[] = [];

    for (let i = 0; i < 150; i++) {
        const tileData: TileData = {
            dashboard: dashboardId,
            status: StatusEnum.EMPTY,
            cellId: `${i + 1}`,
            link: '',
            logo: '',
            tileCaption: '',
            memoryType: '',
            variant: null,
            user: null,
            payment: null,
            reservedAt: '',
            paymentUrl: ''
        };
        const promise = addDoc(collection(db, "tiles"), tileData);
        promises.push(promise);
    }

    await Promise.all(promises);
};

export const getTilesByDashboardId = async (dashboardId: string): Promise<TileResponse[]> => {
    try {
        const tilesCollectionRef = collection(db, "tiles");
        const q = query(tilesCollectionRef, where("dashboard", "==", dashboardId));
        const querySnapshot = await getDocs(q);
        const tiles = querySnapshot.docs.map(createTileFromDoc);

        const [users, payments] = await Promise.all([
            getUsersByDashboardId(dashboardId),
            getPaymentsByDashboardId(dashboardId)
        ]);

        const userLookup = users.reduce((acc, user) => ({ ...acc, [user.id]: user }), {} as Record<string, UserResponse>);
        const paymentLookup = payments.reduce((acc, payment) => ({ ...acc, [payment.id]: payment }), {} as Record<string, PaymentInfoResponse>);

        return tiles.map(tile => {
            if (tile.user && userLookup[tile.user]) {
                tile.userInfo = userLookup[tile.user];
            }
            if (tile.payment && paymentLookup[tile.payment]) {
                tile.paymentInfo = paymentLookup[tile.payment];
            }
            return tile;
        });
    } catch (e) {
        throw e;
    }
};

export const subscribeToTilesByDashboardId = (
    dashboardId: string,
    setTilesCallback: (tiles: TileResponse[]) => void
): (() => void) => {
    try {
        const tilesCollectionRef = collection(db, "tiles");
        const q = query(tilesCollectionRef, where("dashboard", "==", dashboardId));

        return onSnapshot(q, async (querySnapshot) => {
            const tiles: TileResponse[] = querySnapshot.docs.map(doc => createTileFromDoc(doc));

            const [users, payments] = await Promise.all([
                getUsersByDashboardId(dashboardId),
                getPaymentsByDashboardId(dashboardId),
            ]);

            const userLookup: Record<string, UserResponse> = users.reduce((acc, user) => ({...acc, [user.id]: user}), {});
            const paymentLookup: Record<string, PaymentInfoResponse> = payments.reduce((acc, payment) => ({
                ...acc,
                [payment.id]: payment
            }), {});

            const enrichedTiles = tiles.map(tile => {
                if (tile.user && userLookup[tile.user]) {
                    tile.userInfo = userLookup[tile.user];
                }
                if (tile.payment && paymentLookup[tile.payment]) {
                    tile.paymentInfo = paymentLookup[tile.payment];
                }
                return tile;
            });

            setTilesCallback(enrichedTiles);
        });
    } catch (e) {
        throw e;
    }

};

export const createUserAndPaymentAndUpdateTile = async (userInfo: User, tileInfo: Omit<TileData, 'user' | 'payment' | 'status'>, tileId: string): Promise<TileResponse> => {
    try {
        const user = await addUser(userInfo);

        const tileRef = doc(db, "tiles", tileId);
        const tileSnapshot = await getDoc(tileRef);

        if (!tileSnapshot.exists()) {
            throw new Error("Tile does not exist.");
        }

        const nowUTC = new Date().toISOString();


        await updateDoc(tileRef, {
            user: user.id,
            payment: '',
            link: tileInfo.link,
            memoryType: tileInfo.memoryType,
            logo: tileInfo.logo,
            tileCaption: tileInfo.tileCaption,
            variant: tileInfo.variant,
            status: StatusEnum.RESERVED,
            reservedAt: nowUTC,
            paymentUrl: tileInfo.paymentUrl
        });
        return {
            id: tileId,
            dashboard: user.dashboardId,
            status: StatusEnum.RESERVED,
            cellId: tileInfo.cellId,
            link: tileInfo.link,
            logo: tileInfo.logo,
            memoryType: tileInfo.memoryType,
            tileCaption: tileInfo.tileCaption,
            variant: tileInfo.variant,
            user: user.id,
            payment: '',
            paymentInfo: null,
            userInfo: user,
            reservedAt: tileInfo.reservedAt ?? '',
            paymentUrl: tileInfo.paymentUrl
        }
    } catch (error) {
        console.error("Operation failed:", error);
        throw error;
    }
};

