import { combineReducers, configureStore } from '@reduxjs/toolkit';
import mainReducer from './main-reducer';
import dashBoardReducer from "./dashBoard-reducer";
import snackBarReducer from "./snackBar";
import loaderReducer from "./fullScreenLoader";

const rootReducer = combineReducers({
  snackBarReducer,
  loaderReducer,
  mainReducer,
  dashBoardReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: true,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
