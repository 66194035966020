import React from 'react';
import styles from '../styles/help-form.module.scss';
import {ButtonBase, CircularProgress} from '@mui/material';

interface BlueButtonProps {
  label: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  isNewBlue?: boolean;
}

const BlueButton: React.FC<BlueButtonProps> = ({ label, onClick, type, isLoading = false, isNewBlue = false }) => {

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className={styles.buttonWrapper}>
      <ButtonBase
        disabled={isLoading}
        type={type ? type : 'button'}
        onClick={handleClick}
        sx={{ borderRadius: '4px', overflow: 'hidden' }}
      >
          {isLoading ? (
              <div className={styles.blueButton} style={{ padding: '10px 20px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', cursor: isLoading ? 'default' : 'pointer' }}>
                  <CircularProgress style={{ color: '#FFF' }} size={24}/>
              </div>
          ) : (
              <div className={isNewBlue ? styles.button : styles.button2}>{label}</div>
          )}
      </ButtonBase>
    </div>
  );
};
export default BlueButton;
