import React from 'react';
import styles from '../../styles/creation-form.module.scss';

interface PreviewTileProps {
  backgroundImage: string;
  centerImage?: string | null;
  isPreview?: boolean;
}

const PreviewTileImage: React.FC<PreviewTileProps> = ({ backgroundImage, centerImage, isPreview = false }) => {
  return (
    <div className={styles.previewTile}>
      <div className={styles.previewTile__content}>
        <div className={isPreview ? styles.previewTile__image : styles.previewTile__image1}>
          <img src={backgroundImage} alt="backgroundImage" />
        </div>
          {isPreview &&<div className={styles.previewTile__center}>
          {centerImage ? <img src={centerImage} alt="Logo" /> : <div className={styles.previewTile__centerHexagon}></div>}
        </div>}
      </div>
    </div>
  );
};
export default PreviewTileImage;
