import React from 'react';
import styles from '../../styles/layout.module.scss';
import reservedTile from '../../assets/tileReserved.svg';
import filledPlusTile from '../../assets/tileFilledPlus.svg';
import donateTile from '../../assets/tileWhitePreview.svg';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.item}>
        <div className={styles.item__image}>
          <img src={filledPlusTile} alt="Icon" />
        </div>
        <div className={styles.item__body}>
          <div className={styles.item__title}>step 1</div>
          <div className={styles.item__text}>Click on an open tile.</div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.item__image}>
          <img src={reservedTile} alt="Icon" />
        </div>
        <div className={styles.item__body}>
          <div className={styles.item__title}>step 2</div>
          <div className={styles.item__text}>Submit a donation.</div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.item__image}>
          <img src={donateTile} alt="Icon" />
        </div>
        <div className={styles.item__body}>
          <div className={styles.item__title}>step 3</div>
          <div className={styles.item__text}>That donor wall tile is now yours.</div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
