import React from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import {CircularProgress} from "@mui/material";

const FullScreenLoader: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: (theme: Theme) => theme.zIndex.modal,
            }}
        >
            <CircularProgress sx={{ color: '#59C4E7' }} size={168} />
        </Box>
    );
};

export default FullScreenLoader;
