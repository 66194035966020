import { QueryDocumentSnapshot } from 'firebase/firestore';
import {StatusEnum, TileResponse} from "./tile";

export const createTileFromDoc = (doc: QueryDocumentSnapshot): TileResponse => {
    const data = doc.data();

    return {
        id: doc.id,
        dashboard: data.dashboard,
        status: data.status as StatusEnum,
        cellId: data.cellId,
        link: data.link,
        logo: data.logo,
        memoryType: data.memoryType,
        tileCaption: data.tileCaption,
        variant: data.variant,
        user: data.user ? data.user : null,
        payment: data.payment ? data.payment : null,
        paymentUrl: data.paymentUrl ?? ''
    };
}
