import heic2any from "heic2any";

export const convertHEICToJPEG = async (file: Blob) => {
    try {
        const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8
        });
        return URL.createObjectURL(convertedBlob as Blob);
    } catch (error) {
        console.error("Error converting HEIC file:", error);
        return null;
    }
}
