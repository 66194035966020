import emptyPolygon from '../assets/tileEmpty.svg';
import reservedPolygon from '../assets/tileReserved.svg';
import memoryPolygon from '../assets/tileMemory.svg';
import darkPolygon from '../assets/creation-form/dark-polygon.svg';
import bluePolygon from '../assets/tileWithoutImage.svg';
import greenPolygon from '../assets/green-polygon.svg';
import { StatusEnum, TileData, VariantEnum } from '../backend/functions/tile/tile';

const polygonMap = (el: TileData) => ({
  [StatusEnum.EMPTY]: emptyPolygon,
  [StatusEnum.RESERVED]: reservedPolygon,
  [StatusEnum.APPROVED]: {
    [VariantEnum.FIRST]: emptyPolygon,
    [VariantEnum.SECOND]: emptyPolygon,
    [VariantEnum.THIRD]: el.logo ? emptyPolygon :  memoryPolygon,
    [VariantEnum.FOURTH]: bluePolygon,
    default: greenPolygon,
  },
  default: emptyPolygon,
});

export const getPolygon = (el: TileData) => {
  if (el.status === StatusEnum.APPROVED) {
    return polygonMap(el)[StatusEnum.APPROVED][el.variant!] || polygonMap(el)[StatusEnum.APPROVED]['default'];
  }

  return polygonMap(el)[el.status] || polygonMap(el)['default'];
};

export const getDefaultPolygon = () => {
  return emptyPolygon;
}
