import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../db";
import heic2any from "heic2any";

export const uploadFileAndLinkToTile = async (file: File, tileId: string): Promise<string> => {
    if (file.type === "image/heic") {
        const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.9
        });

        file = new File([convertedBlob as Blob], file.name.replace(/\..+$/, ".jpeg"), {
            type: "image/jpeg",
        });
    }
    const storageRef = ref(storage, 'attachment/' + tileId);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
        await uploadTask;
        return await getDownloadURL(storageRef);
    } catch (error) {
        console.error("Error uploading file and saving URL to Firestore", error);
        throw error;
    }
}
