import {EMAIL_REG_EXP} from "./email-regular-exp";

export const isValidEmail = (email: string) => {
    return EMAIL_REG_EXP.test(email.toLowerCase())
}


export const isValidFile = (file: string) => {
    return !!file
}

export const isValidName = (name: string) => {
    return name.trim().length >= 3
}

export const isValidField = (value: string) => !!value.trim();

export function isValidUrl(string: string) {
    let urlString = string;
    if (string.startsWith('www.')) {
        urlString = `https://${string}`;
    }
    try {
        new URL(urlString);
        return true;
    } catch (_) {
        return false;
    }
}
