import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {TileData, TileResponse} from '../backend/functions/tile/tile';

interface mainReducer {
  currentApprovedCell: TileData | null;
  currentCellId: string | null;
  zoom: number;
  isHelpPopupOpen: boolean;
}

const initialState: mainReducer = {
  currentApprovedCell: null,
  currentCellId: null,
  zoom: 1,
  isHelpPopupOpen: false,
};

const mainSlice = createSlice({
  name: 'mainReducer',
  initialState,
  reducers: {
    setCurrentCellId(state, action: PayloadAction<string | null>) {
      state.currentCellId = action.payload;
    },

    setCurrentApprovedCell(state, action: PayloadAction<TileData | null>) {
      state.currentApprovedCell = action.payload;
    },

    setCurrentZoom(state, action: PayloadAction<number>) {
      state.zoom = action.payload;
    },

    setHelpPopupOpen(state, action: PayloadAction<boolean>) {
      state.isHelpPopupOpen = action.payload;
    },
  },
});

export const {setCurrentApprovedCell, setCurrentCellId, setCurrentZoom, setHelpPopupOpen } = mainSlice.actions;

export default mainSlice.reducer;
