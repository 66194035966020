import {QueryDocumentSnapshot} from "firebase/firestore";
import {DashBoardInfoResponse} from "./board";

export const createDashBoardFromDoc = (doc: QueryDocumentSnapshot): DashBoardInfoResponse => {
    const data = doc.data();

    return {
        id: doc.id,
        name_wall: data.name_wall,
        raise_amount: data.raise_amount,
        website: data.website
    };
}
