import { db } from '../../db';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import {createDashBoardFromDoc} from "./utils";
import {getTilesByDashboardId, TileResponse} from "../tile/tile";

export interface DashBoardInfoResponse {
    id?: string;
    name_wall: string;
    raise_amount: number;
    website: string;
}

export interface DashBoardDataInfoResponse {
    dashBoardData: DashBoardInfoResponse | null;
    tilesData: TileResponse[] | null
}

export const addDashboard = async () => {
    const docRef = await addDoc(collection(db, "dashboards"), {
        name_wall: "founders wall",
        raise_amount: 150000,
        website: "https://pfcommunity.org/"
    });
    return docRef.id;
};


export const getDashBoardDataById = async (id: string): Promise<DashBoardDataInfoResponse | null> => {
    try {
        const docRef = doc(db, "dashboards", id);
        const docSnapPromise = getDoc(docRef);
        const tilesDashboardDataPromise = getTilesByDashboardId(id);

        const [docSnap, tilesDashboardData] = await Promise.all([docSnapPromise, tilesDashboardDataPromise]);
        if (docSnap.exists()) {
            return { dashBoardData: createDashBoardFromDoc(docSnap), tilesData: tilesDashboardData };
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (e) {
        return null;
    }
};
