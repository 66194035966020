import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../db";
import {createPaymentFromDoc} from "./utils";

export interface PaymentInfo {
    userId: string;
    amount: number;
    cellId: string
    dashboardId: string;
    transactionId: string;
    orderId: string;
    status: 'Initialize' | 'Completed';
}

export interface PaymentInfoResponse {
    id: string;
    userId: string;
    amount: number;
    cellId: string
    dashboardId: string;
    transactionId: string;
    orderId: string;
    status: 'Initialize' | 'Completed';
}

export const addPayment = async (paymentInfo: PaymentInfo): Promise<PaymentInfoResponse> => {
    const paymentRef = await addDoc(collection(db, "payments"), {
        transactionId: paymentInfo.transactionId,
        orderId: paymentInfo.orderId,
        dashboardId: paymentInfo.dashboardId,
        status: paymentInfo.status,
        cellId: paymentInfo.cellId,
        userId: paymentInfo.userId,
        amount: paymentInfo.amount
    });

    return {
        ...paymentInfo,
        id: paymentRef.id
    };
};

export const getPaymentsByDashboardId = async (dashboardId: string): Promise<PaymentInfoResponse[]> => {
    try {
        const paymentsRef = collection(db, "payments");
        const q = query(paymentsRef, where("dashboardId", "==", dashboardId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(createPaymentFromDoc);
    } catch (e) {
        throw e;
    }
};
