import React from 'react';
import Popup from './Popup';
import styles from '../styles/help-form.module.scss';
import {Box, ButtonBase} from '@mui/material';
import BlueButton from "./BlueButton";

interface SuccessModalProps {
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({onClose}) => {

    const handleSubmit = () => {
        onClose();
    };

    return (
          <Popup setClose={onClose}>
                <h2 className={styles.title}>Successful reservation</h2>
                <Box sx={{ marginTop: '24px', textAlign:'center' }}>
                    <h3 className={styles.successText}>Thanks for the donation!</h3>
                </Box>
                <Box className={styles.successDescription} sx={{ marginTop: '24px', textAlign:'center' }}>
                    You have successfully reserved a tile. When administrator confirms your donation, your tile will appear on the donor wall. If the donation is rejected, the money will automatically returned to you.
                </Box>
                <Box sx={{
                    borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 10%',
                }}>
                   <BlueButton label="Ok" onClick={handleSubmit}/>
                </Box>
        </Popup>
    );
};
export default SuccessModal;
