import {useDispatch} from 'react-redux';
import {clearSnackBarMessage, setSnackBarMessage} from '../store/snackBar';
import {useCallback} from 'react';
import {SnackBarType} from "../store/snackBar";

type UseSnackbarOptions = {
    message: string;
    type: SnackBarType;
};

const useSnackbar = () => {
    const dispatch = useDispatch();

    return useCallback(({message, type}: UseSnackbarOptions) => {
        dispatch(setSnackBarMessage({message, type}));

        const timeoutId = setTimeout(() => {
            dispatch(clearSnackBarMessage());
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [dispatch]);
};

export default useSnackbar;
