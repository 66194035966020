import { TextField } from '@mui/material';
import React from 'react';

interface CustomFieldProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label: string;
  placeholder: string;
  marginBottom?: string;
  isRequired?: boolean;
  rows?: number;
  isError: string;
}

const CustomField: React.FC<CustomFieldProps> = ({ onChange, value, label, placeholder, marginBottom, isRequired, rows, isError }) => {
  const text = isRequired ? `${label} *` : label;
  const helperText = isError === 'required' ? 'This is required field' : isError;
  return (
    <TextField
      error={!!isError}
      rows={rows}
      multiline={rows ? true : false}
      variant="outlined"
      fullWidth
      placeholder={placeholder}
      label={text}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{ marginBottom,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#BDBDBD',
          },
          '&:hover fieldset': {
            borderColor: '#59C4E7',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#29A4CC',
          },
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: isError ? '#D32F2F' : '#05222B',
        },
        ...(isError && {
          '& .MuiInputLabel-root': {
            color: '#D32F2F',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#D32F2F',
            },
          },
          '& .MuiFormHelperText-root': {
            color: '#D32F2F',
          },
        }),
      }}
      onChange={onChange}
      helperText={helperText}
    />
  );
};
export default CustomField;
