import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBu1u4fqEjJp2KRz2xiPosINcF9UminaHo",
    authDomain: "fundzzz-3c9d7.firebaseapp.com",
    projectId: "fundzzz-3c9d7",
    storageBucket: "fundzzz-3c9d7.appspot.com",
    messagingSenderId: "426177231401",
    appId: "1:426177231401:web:8e0507756acfeb0ce8fa38",
    measurementId: "G-6MHSDYX93J"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const storage = getStorage(app);

export { db, storage };
