import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import { Box, Typography } from '@mui/material';
import BlueButton from './BlueButton';

interface WelcomeVideoProps {
  setPopupClose: () => void;
}

const WelcomeVideo: React.FC<WelcomeVideoProps> = ({ setPopupClose }) => {
  return (
    <Popup setClose={() => setPopupClose()}>
      <Box>
       {/* <Typography
          component="h2"
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            color: '#101010',
            marginBottom: '32px',
            textAlign: 'center',
          }}
        >
          Hello
        </Typography>
        <Typography
          component="div"
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            color: '#101010',
            marginBottom: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            '@media (max-width:700px)': {
              textTransform: 'none',
            },
          }}
        >
          A message from the Non-Profit that explains what they are raising for
        </Typography>*/}
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            color: '#101010',
            flexWrap: 'wrap',
            fontSize: '30px',
            marginTop: '32px',
            marginBottom: '32px',
          }}
        >
            <video id="myVideo" width="100%" height="100%" controls autoPlay={true} muted={true}>
                <source src="/videos/introduction_with_subtitles.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Box>
        <BlueButton label="close" onClick={setPopupClose} isNewBlue={true} />
      </Box>
    </Popup>
  );
};
export default WelcomeVideo;
